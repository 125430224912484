// Required for dev hot reload
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
}
    
// Vue
import { createApp } from 'vue';
// Algolia InstantSearch
import InstantSearch from 'vue-instantsearch/vue3/es';

// Products Landing Page - Search
import ProductsSearch from './ProductsSearch.vue';
  
let isProductSearchPage = document.getElementById('product-search');
if (isProductSearchPage) {
  const productSearchApp = createApp(ProductsSearch);
    productSearchApp.use(InstantSearch);
    productSearchApp.mount('#product-search');
}


// Products Landing Page - Search
import SeriesSearch from './SeriesSearch.vue';
  
let isSeriesSearchPage = document.getElementById('series-search');
if (isSeriesSearchPage) {
    const seriesSearchApp = createApp(SeriesSearch);
    seriesSearchApp.use(InstantSearch);
    seriesSearchApp.mount('#series-search');
}
  
  
// Product Single - Specs
import ProductSpecs from './components/ProductSpecs.vue';
  
let isSingleProductPage = document.getElementById('product-specs');
if (isSingleProductPage) {
  const productSpecsApp = createApp({
      components: {
          ProductSpecs
      }
  }).mount('#product-specs');
}


// Product Single - Specs
import Gallery from './Gallery.vue';

let isGalleryPage = document.getElementById('image-gallery');
if (isGalleryPage) {
    const galleryApp = createApp(Gallery);
    galleryApp.use(InstantSearch);
    galleryApp.mount('#image-gallery');
}
